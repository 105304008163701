module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TimYsewyn.be","short_name":"TimYsewyn.be","start_url":"https://timysewyn.be","background_color":"#29b6f6","theme_color":"#29b6f6","lang":"en","display":"standalone","legacy":false,"icons":[{"src":"/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-68160333-2","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/opt/build/repo/src/templates/posts.js"},"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embed-video","options":{"urlOverrides":[{"id":"youtube"}]}},"gatsby-remark-responsive-iframe",{"resolve":"/opt/build/repo/plugins/gatsby-remark-images-grid","options":{"className":"gatsbyRemarkImagesGrid","gridGap":"20px","margin":"20px auto"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1344,"withWebp":true}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}},"/opt/build/repo/plugins/gatsby-remark-fix-descendants-of-p"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
